@tailwind base;
@tailwind components;
@tailwind utilities;

.transition-max-height {
  transition: max-height 0.7s ease-in-out;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.float-animation {
  @apply animate-float;
}

.float-animation:hover {
  animation-play-state: paused;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}
